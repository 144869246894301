.chat_wrapper{
    display: flex;
    margin: 0 -10px 20px;
    height: calc(100vh - 110px);
    position: relative;
}
.chat_back{
    display: none;
}
.user_list_wrapper{
    max-width: 395px;
    width: 100%;
    margin: 0 10px;
    background: #ffffff;
    padding: 28px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
}
.user_list_wrapper h4{
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #151515;
    margin: 0 0 28px;
}
.user_list_wrapper .search_wrapper{
    background: #F7F7F7;
    border-radius: 90px;
    padding: 10px 20px;
    display: flex;
    margin-bottom: 8px;
}
.user_list_wrapper .search_wrapper .search-bar{
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: #8C8C8C;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0 0 0 15px;
    width: 100%;
}
.user_list_wrapper .search_wrapper .search_icon_wrapper{
    color: #6B6F78;
    font-size: 18px;
}
.user_list_wrapper .inbox_chat_wrapper{
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 -15px;
}
.user_list_wrapper .chat_list_item{
    display: flex;
    padding: 8px 15px;
    cursor: pointer;
}
.user_list_wrapper .chat_list_item.active_chat{
    background:#ebebeb;
}
.user_list_wrapper .chat_list_item .avatar_wrapper{
    width: 65px;
    height: 65px;
    margin-right: 15px;
    flex-shrink: 0;
}
.user_list_wrapper .chat_list_item .avatar_wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    object-position: center;
}
.user_list_wrapper .chat_list_data_name{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.41px;
    color: #151515;
    margin-right: 5px;
    overflow-wrap: anywhere;
}
.user_list_wrapper .chat_list_data{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
}
.user_list_wrapper .chat_list_data > div{
    display: flex;
    align-items: flex-start;
    padding: 4px 0;
}
.user_list_wrapper .chat_list_data_date{
    font-size: 14px;
    line-height: 16px;
    color: #979797;
    margin-left: auto;
    flex-shrink: 0;
}
.user_list_wrapper .chat_list_data_message{
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: #979797;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 160px;
}
.user_list_wrapper .chat_list_data_message .recrent-file{
    width: 18px;
}
.user_list_wrapper .chat_list_data_read{
    margin-left: auto;
}
.user_list_wrapper .chat_list_data_read .double_check{
    stroke: #27AE60;
}
.user_list_wrapper .chat_list_data_read .unread{
    width: 20px;
    height: 20px;
    background: #2F80ED;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    border-radius: 50%;
}
.welcome-msg{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 10px;
    background: #ffffff;
    border-radius: 4px;
    padding: 28px;
    font-size: 28px;
    font-style: italic;
    color: #cbcbcb;
}
.mesgs{
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
}
.modal-image{
    text-align: center;
}
.modal-body video{
    margin: auto;
    display: block;
    max-width: 100%;
}
.modal-image img{
    max-width: 100%;
    max-height: 320px;
    min-width: 200px;
    object-fit: cover;
}
.mesgs .mainTopDiv{
    display: flex;
    padding: 17px 28px;
}
.mesgs .mainTopDiv .group-admin{
    display: flex;
    align-items: center;
}
.mainTopDiv .group-users{
    margin-left: auto;
}
.mesgs .select-search .select-search__value{
    width: 200px;
}
.mesgs .msg_history{
    padding: 22px 28px;
    flex: 1;
    overflow-y: auto;
    border-top: 1px solid rgba(0,0,0,0.1);
}
.mesgs .outgoing_msg,
.mesgs .incoming_msg{
    display: flex;
    margin-bottom: 18px;
}
.mesgs .outgoing_msg{
    flex-direction: row-reverse;
    padding-left: 61px;
}
.mesgs .incoming_msg{
    padding-right: 61px;
}
.mesgs .outgoing_msg_img{
    width: 46px;
    height: 46px;
    margin-left: 15px;
    flex-shrink: 0;
}
.mesgs .incoming_msg_img{
    width: 46px;
    height: 46px;
    margin-right: 15px;
    flex-shrink: 0;
}
.mesgs .outgoing_msg_img img,
.mesgs .incoming_msg_img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}
.mesgs .received_withd_msg_content{
    padding: 16px;
    border-radius: 4px;
    background: #F7F7F7;
    font-size: 16px;
    line-height: 26px;
    color: #151515;
    width: fit-content;
    margin-bottom: 6px;
    max-width: 520px;
    word-break: break-word;
}
.mesgs .time_date{
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: #979797;
}
.mesgs .sent_msg{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.mesgs .sent_msg_content{
    padding: 16px;
    border-radius: 4px;
    background: #2F80ED;
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    width: fit-content;
    margin-bottom: 6px;
    max-width: 520px;
    word-break: break-word;
}
.mesgs .sent_msg .time_date{
    text-align: right;
}
.mesgs .chat-file{
    max-height: 400px;
    width: 120px !important;
    border-radius: 4px;
    margin-bottom: 6px;
}
.mesgs video{
    border-radius: 4px;
    margin-bottom: 6px;
}
.mesgs .type_msg{
    padding: 18px 28px;
}
.mesgs .input_msg_write{
    display: flex;
    position: relative;
    align-items: center;
}
.mesgs .not-added{
    background: #ffcdd2;
    text-align: center;
}
.mesgs .share-file{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #2F80ED;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2F80ED;
    font-size: 10px;
    cursor: pointer;
    margin-right: 18px;
}
.mesgs .write_msg_chat{
    background: #F7F7F7;
    border-radius: 4px;
    border: none;
    height: 46px;
    width: 100%;
    flex: 1;
    padding: 13px 30px 13px 15px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #8C8C8C;
    resize: none;
}
.mesgs .msg_send_btn{
    background: transparent;
    border: none;
    position: absolute;
    bottom: 13px;
    right: 20px;
    width: 20px;
    height: 20px;
    padding: 0;
    color: #2F80ED;
}
@media (max-width: 768px) {
    .user_list_wrapper{
        padding: 16px 20px 0;
        margin: 0;
        max-width: 100%;
    }
    .user_list_wrapper h4{
        display: none;
    }
    .welcome-msg{
        display: none;
    }
    .mesgs{
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        height: calc(100vh - 110px);
        overflow: hidden;
    }
    .user_list_wrapper .chat_list_data{
        border-bottom: 1px solid rgba(0,0,0,0.2);
    }
    .mainTopDiv .group-users{
        width: 45px;
    }
    .mesgs .select-search .select-search__value{
        border: none;
        width: auto;
    }
    .select-search .select-search__value input{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
    .mesgs .select-search .select-search__select{
        width: 200px;
        right: 0;
    }
    .mesgs .mainTopDiv{
        padding: 16px 20px 16px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
    .mesgs .msg_history{
        border-top: 0;
        padding: 22px 20px;
    }
    .mesgs .type_msg{
        padding: 18px 20px;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
    .chat_back{
        font-size: 16px;
        width: 30px;
        height: 30px;
        display: inline-flex;
        justify-content: center;
        margin-right: 15px;
        align-items: center;
    }
}