.libi-admin-btn{
    color: #2F80ED !important;
    border: 2px solid #2F80ED !important;
    border-radius: 4px !important;
    background: transparent !important;
}
.libi-admin-btn:hover{
    color: #2F80ED !important;
    border: 2px solid #2F80ED !important;
    border-radius: 4px !important;
    background: transparent !important;
}
.libi-admin-btn:not(:first-child):not(:last-child){
    margin-left: 10px;
}
.multiple-photo-field{
    display: flex;
    flex-wrap: wrap;
}
.multiple-photo-field .preview-wrapper{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex: 1 0 0%;
}
.multiple-photo-field .uploader-wrapper{
    flex: 0 0 auto;
    padding: 15px 10px;
}
.multiple-photo-field .preview-item{
    position: relative;
    padding: 15px 10px;
}
.multiple-photo-field .preview-item .remove-image-btn{
    position: absolute;
    right: 25px;
    top: 30px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    z-index: 1;
}
.multiple-photo-field .preview-item img,
.multiple-photo-field .preview-item video{
    width: 200px;
    height: 200px;
    object-fit: cover;
    display: block;
}
.multiple-photo-field .preview-item .meta{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #616161;
    margin-top: 14px;
}
.multiple-photo-field .preview-item .meta span{
    color: #000000;
}
.multiple-photo-field .preview-item .meta input{
    width: 155px;
}
.multiple-photo-field .uploader-item-wrapper{
    position: relative;
    width: 200px;
    height: 200px;
    border: 2px dashed #6E777F;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.multiple-photo-field .uploader-item-wrapper .uploader-icon{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #2F80ED;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.multiple-photo-field .uploader-item-wrapper .uploader-icon i{
    font-size: 20px;
}
.multiple-photo-field .uploader-item-wrapper .uploader-description{
    margin-top: 11px;
    font-size: 17px;
    line-height: 20px;
    color: #483946;
    opacity: 0.5;
}
.multiple-photo-field .uploader-item-wrapper input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
@media (max-width: 1024px) {
    .multiple-photo-field{
        flex-direction: column-reverse;
    }
    .multiple-photo-field .preview-item img,
    .multiple-photo-field .preview-item video,
    .multiple-photo-field .uploader-item-wrapper{
        width: 160px;
        height: 160px;
    }
    .multiple-photo-field .preview-item .meta{
        font-size: 12px;
    }
    .multiple-photo-field .preview-item .meta input{
        width: 120px;
    }
    .multiple-photo-field .uploader-item-wrapper .uploader-description{
        font-size: 13px;
    }
}
.CustomNumberField{
    background: #F7F7F8;
    border-radius: 4px;
    color: #565656;
    padding: 8px 12px;
    height: 40px;
    box-shadow: none;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
}
.CustomNumberField button{
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;
    border: 2px solid #C5C7CD;
    background: transparent;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    color: #C5C7CD;
    display: flex;
    justify-content: center;
    align-items: center;
}
.CustomNumberField button i{
    font-size: 10px;
    line-height: 10px;
    width: 10px;
    height: 10px;
}
.CustomNumberField .input{
    background: transparent;
    border: 0;
    width: 100%;
    text-align: center;
    user-select: none;
}
.CustomNumberField button:disabled,
.CustomNumberField .input.disabled {
    opacity: 0.5;
}
.content select.form-control{
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L7 7L13 1' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 18px);
    background-position-y: 50%;
    padding-right: 35px;
}
.card-plain .title{
    margin-bottom: 10px;
}
@media (max-width: 768px) {
    .notification-button{
        height: auto;
    }
    .content > .libi-admin-btn,
    form > .libi-admin-btn,
    .header .libi-admin-btn{
        width: 100%;
        margin: 0 0 10px !important;
    }
    .libi-admin-btn[type=submit]{
        width: 100%;
    }
    .react-file-reader-button .libi-admin-btn{
        width: 100%;
    }
}
.treatment_product_rule_wrapper{
    display: flex;
    flex-direction: row;
}
.treatment_product_rule_wrapper span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    width: 53px;
    height: 42px;
    border-radius: 21px;
    margin-right: 18px;
}
@media (max-width: 992px){
    .treatment_product_rule_wrapper{
        flex-direction: row-reverse;
    }
    .treatment_product_rule_wrapper span {
        margin-left: 12px;
        margin-right: 0;
    }
}
.fake_link:hover{
    cursor: pointer;
    color: #42d0ed;
}
.fake_link.active{
    color: #42d0ed;
}
.libi-admin-btn.red{
    color: #92001A !important;
    border: 2px solid #92001A !important;
}
.message_action_btns > *{
    cursor: pointer;
}
span.coma span::after {
    content: ",";
    margin-right: 0.3em;
}
span.coma span:last-child::after {
    content: "";
    margin-right: 0;
}
.btn{
    border: inherit;
}
.content h5{
    display: flex;
    word-break: break-word;
}
.content h5 .form-group{
    flex: 1;
}
.customer-details > .container-fluid > .row .card h5 span{
    min-width: 140px;
}
.form_notification{
    color: red;
}
/*.treatmentRequestLoadingPopupWrapper{*/
/*    position: fixed;*/
/*    z-index: 3;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background: rgba(100, 100, 100, 0.6);*/
/*}*/
/*.treatmentRequestLoadingPopup{*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    top: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*}*/
.loadingLine{
    width: 100%;
    height: 20px;
    border-radius: 4px;
    border: 1px solid gray;
    background: #fff;
    position: relative;
}
.loadingLine > *{
    width: 20px;
    height: calc(100% - 4px);
    margin: 2px;
    border-radius: 4px;
    background: #2F80ED;
    position: absolute;
    left: 0;
    animation-name: loadingLine;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}
@keyframes loadingLine {
    0%   {left:0px;}
    50%  {left:calc(100% - 24px);}
    100%  {left:0;}
}
.table > thead > tr > th.sorting{
    padding: 12px 20px 12px 8px !important;
}
.mb-3{
    margin-bottom: 15px;
}

.text_green{
    color: green;
}
.text_red{
    color: red;
}