
.addQuest-block{
  background: #fafafa !important;
}
.btn-success.addQuest-btn {
  border-color: #e91e63;
  color: #e91e63;
}
.addQuest-btn.btn-success:hover,
.addQuest-btn.btn-success:active,
.addQuest-btn.btn-success:active:hover{
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}
.question-td{
  background-color: #e91e63;
  border-color: #e91e63;
}
.answer-td{
  background: #aed581;
}
.que-ansTable th{
  font-weight: bold !important;
  color: #000000 !important;
}


.traet-table{
  margin-top: 20px;
}
.traet-table td{
  border: 1px solid #ddd;
}
.traet-table .table > thead > tr > th {
  color: #fff;
  background-color: #9a9a9a;
}
.traet-table th{
  min-width: 80px;
}